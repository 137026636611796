<template>
    <div>
        <b-row>
            <b-col>
                <b-card no-body>
                    <b-card-header>
                        <strong>User History</strong>
                    </b-card-header>
                    <b-card-body>
                        <b-row>
                            <b-col cols="md-3">
                                <b-form-group label-for="search-lastname" label="Last Name:">
                                    <b-input-group size="sm">
                                        <b-input id="search-lastname" placeholder="Last Name" v-model="criteria.lastname"/>
                                        <b-input-group-addon>
                                            <b-button @click="criteria.lastname = null"><font-awesome-icon icon="times"/></b-button>
                                        </b-input-group-addon>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                            <b-col cols="md-3">
                                <b-form-group label-for="search-ubcid" label="UBC ID:">
                                    <b-input-group size="sm">
                                        <b-input id="search-ubcid"
                                                 v-model="criteria.ubcId"
                                                 placeholder="UBC ID"  />
<!--                                        <ubc-id id="search-ubcid" v-model="criteria.ubcId" input-class="form-control form-control-sm"/>-->
                                        <b-input-group-addon>
                                            <b-button @click="criteria.ubcId = null"><font-awesome-icon icon="times"/></b-button>
                                        </b-input-group-addon>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>


                            <b-col cols="md-3">
                                <b-form-group label-for="search-username" label="Username:">
                                    <b-input-group size="sm">
                                        <b-input id="search-username" placeholder="Username" v-model="criteria.username"/>
                                        <b-input-group-addon>
                                            <b-button @click="criteria.username = null"><font-awesome-icon icon="times"/></b-button>
                                        </b-input-group-addon>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                            <b-col cols="md-3">
                                <b-form-group label-for="search-security-level" label="Security Level:">
                                    <b-input-group size="sm">
                                        <b-select id="search-security-level" v-model="criteria.role" :options="roleOptions">
                                            <template v-slot:first>
                                                <option :value="null"> - </option>
                                            </template>
                                        </b-select>
                                        <b-input-group-addon>
                                            <b-button @click="criteria.role = null"><font-awesome-icon icon="times"/></b-button>
                                        </b-input-group-addon>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-card-body>
                    <b-card-footer class="clearfix">
                        <b-button-group size="sm">
                            <b-button variant="primary" @click="doSearch"><b-spinner v-if="searching" small type="grow"></b-spinner>Search</b-button>
                        </b-button-group>
                    </b-card-footer>
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-card>
                    <b-row>
                        <b-col cols="4">
                            <b-pagination :total-rows="searchResults.length" :per-page="perPage" v-model="currentPage"/>
                        </b-col>
                        <b-col cols="8">
                            <b-form-group :label="'Records Per Page: ' + perPage">
                                <b-input type="range" v-model="perPage" min="10" max="100" step="10"></b-input>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        Page {{ currentPage }} of {{ pages }}
                    </b-row>
                    <b-row>
                        <b-table small striped hover bordered no-local-sorting stacked="md" head-variant="dark"
                                    ref="searchResultsTable"
                                    selectable
                                    select-mode="single"
                                    selected-variant="warning"
                                    @row-selected="onRowSelected"
                                    :items="searchResults"
                                    :fields="tableFields"
                                    :sort-by.sync="sortKey"
                                    :sort-desc.sync="sortDesc"
                                    :per-page="perPage"
                                    :current-page="currentPage">
                            <!-- <template v-slot:cell(ubcId)="data">
                                [{{ data.index + 1 }}] - {{ data.item.ubcId }}
                            </template> -->
                            <!-- <template v-slot:cell(firstName)="data">
                                {{ data.item.firstName }}
                            </template>
                            <template v-slot:cell(lastName)="data">
                                {{ data.item.lastName }}
                            </template> -->
                            <!-- <template v-slot:cell(role)="data">
                                {{ data.item.securityLevel.toString() }}
                            </template> -->
                            <!-- <template v-slot:cell(status)="data">
                                {{ data.item.status }}
                            </template> -->
                            <!-- <template v-slot:cell(organization.name)="data">
                                {{ data.item.organization.name }}
                            </template> -->
                        </b-table>
                    </b-row>
                    <b-row>
                        <b-col cols="12">
                            <b-pagination :total-rows="searchResults.length" :per-page="perPage" v-model="currentPage"/>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-card>
                    <b-row>
                        <b-col cols="3">
                            <b-form-group label-size="sm" label="Date Range:">
                                <picker-of-dates v-model="dateRange"
                                                 format="M/d/yyyy"
                                                 clearable range/>
                            </b-form-group>
                        </b-col>
                        <b-col cols="4" offset="5">
                            <b-form-group
                                    label-for="display-purposes"
                                    label="Selected User:"
                                    :invalid-feedback="targetUserSelectedMessage"
                                    :valid-feedback="targetUserSelectedMessage"
                                    :state="isTargetUserSelected">
                                <b-form-input
                                    id="display-purposes"
                                    size="lg"
                                    readonly
                                    v-model="selectedUser"
                                    :state="isTargetUserSelected"></b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col class="text-right">
                            <b-button-group size="sm">
                                <b-button class="mr-2" variant="dark" @click="getPdf">Generate PDF</b-button>
                                <b-button variant="info" @click="clearForm">Clear</b-button>
                            </b-button-group>
                        </b-col>
                    </b-row>

                </b-card>
            </b-col>
        </b-row>
    </div>
</template>


<script>
import { Component, Vue } from 'vue-property-decorator';
import _ from 'underscore';
import UbcId from '@/components/shared/UbcId.vue';
import PickerOfDates from '@/components/shared/PickerOfDates';
import { date } from '@/util/formatters';
import download from '@/util/downloader';
import Breadcrumb from '@/views/menu/breadcrumb/breadcrumb';
import {RoleOptions} from '@/model/security_level';
import {sprintf} from 'sprintf-js';
import {errorModalOptions} from "../../../util/formatters";

@Component({
    components: {
        UbcId,
        PickerOfDates
    }
})
export default class AuditUserHistory extends Vue {

    isSearching = false;

    reportDateRange = [null,null];
    reportTargetUser = {};

    sortKey = null;
    sortDesc = false;
    currPage = 1;
    perPage = 10;

    get roleOptions() {
        const activeUser = this.$store.getters['userSession/getUser'];
        const options = _.chain(RoleOptions)
            .filter((o) => {
                // For Administrators, allow the search for any user type
                // For Instructors, allow searching for Mentors and Trainees
                // For Mentors, only allow searching for Trainees
                return activeUser.isAnAdministrator() || activeUser.securityLevel.ord < o.value.ord;
            })
            .value();
        return options;
    }

    get tableFields() {
        return [{
            key: 'ubcId',
            label: 'UBC ID',
            sortable: true
        }, {
            key: 'firstName',
            label: 'First Name',
            sortable: true
        }, {
            key: 'lastName',
            label: 'Last Name',
            sortable: true
        }, {
            key: 'securityLevel',
            label: 'Role',
            sortable: true
        }, {
            key: 'status',
            label: 'Status',
            sortable: true
        }, {
            key: 'organization.name',
            label: 'Organization',
            sortable: true
        }];
    }

    get isTargetUserSelected() {
        return null;
        //return this.reportTargetUser.id !== undefined;
    }

    get targetUserSelectedMessage() {
        return this.isTargetUserSelected ? '' : 'Please select a user from the search results table';
    }

    get dateRange() {
        return this.reportDateRange;
    }

    set dateRange(dates) {
        this.reportDateRange = dates;
    }

    get selectedUser() {
        const user = this.reportTargetUser;
        if (user.firstName === undefined) {
            return undefined;
        } else {
            return user.firstName + " " + user.lastName;
        }
    }

    get searching() { return this.isSearching; }

    get currentPage() {
        if (this.currPage > this.pages) {
            return this.pages;
        } else {
            return this.currPage;
        }
    }

    set currentPage(num) {
        const page = parseInt(num, 10);
        switch (true) {
            case _.isNaN(page):
            case 0 >= page:
                this.currPage = 1;
                break;
            case page > this.pages:
                this.currPage = this.pages;
                break;
            default:
                this.currPage = page;
        }
    }

    get pages() {
        return Math.ceil(this.searchResults.length / this.perPage) || 1;
    }

    get criteria() {
        return this.$store.getters['users/searchCriteria'];
    }


    onRowSelected(selected) {
        const user = selected.pop();
        if (user) {
            this.reportTargetUser = user;
        } else {
            this.reportTargetUser = {};
        }
    }

    clearForm() {
        this.criteria.clear();
        this.reportTargetUser = {};
        this.reportDateRange = [null,null];
        this.$store.commit('users/setUsers', []);
    }

    get searchResults() {
        const users = this.$store.getters['users/users'];
        const sortedRows = _.chain(users)
            .sortBy((u) => {
                switch (this.sortKey) {
                    case 'ubcId': return u.ubcId || "";
                    case 'firstName': return u.firstName;
                    case 'lastName': return u.lastName;
                    case 'role': return u.securityLevel.toString();
                    case 'status': return u.status;
                    case 'organization.name': return u.organization.name || "";
                    default: return u.lastName;
                }
            })
            .value();
        return this.sortDesc ? sortedRows.reverse() : sortedRows;
    }

    get fields() {
        return fields;
    }

    async doSearch() {
        this.isSearching = true;
        const criteria = this.criteria;
        try {
            const {limited, size} = await this.$store.dispatch('users/search', criteria.serialize());
            this.currentPage = 1;
            this.isSearching = false;
            if (limited) {
                this.$bvToast.toast(sprintf('The search results have been limited to %d users. Please choose more specific search criteria.', size), {
                    variant: 'danger',
                    title: 'Results Truncated',
                    solid: true
                });
            }
        }
        catch (error) {
            await this.$bvModal.msgBoxOk(error.message, errorModalOptions);
        }
    }

    async getPdf() {
        if (!this.isReady()) {
            return;
        }
        const reqData = {};
        reqData.userProfileId = this.reportTargetUser.id;
        reqData.fromDate = date(this.reportDateRange[0], date.DEFAULT_DATE_FORMAT) + " 00:00:00";
        reqData.toDate = date(this.reportDateRange[1], date.DEFAULT_DATE_FORMAT) + " 23:59:99";
        try {
            await download('POST', '/api/v1/report/audit-user-history', JSON.stringify(reqData));
        }
        catch (error) {
            await this.$bvModal.msgBoxOk(error.message, errorModalOptions);
        }
    }

    isReady() {
        if (!this.reportTargetUser.id) {
            alert("A user must be selected");
            return false;
        } else if (!this.reportDateRange[0] || !this.reportDateRange[1]) {
            alert("Please select a date range");
            return false;
        }
        return true;
    }

    mounted() {
        this.$store.commit('addBreadcrumb', Breadcrumb.create('User History', null, true));
        this.clearForm();
    }

    async beforeDestroy() {
        this.criteria.clear();
        this.$store.commit('users/setUsers', []);
    }

}
</script>


<style scoped>

</style>
