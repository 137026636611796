<template>
    <div>
        <b-row>
            <b-col>
                <b-card no-body>
                    <b-card-header>
                        <strong>{{title}}</strong>
                    </b-card-header>
                    <b-card-body>
                        <b-row>
                            <b-col>
                                <b-form-group>
                                    <template #label>
                                        <strong>Date Exception Granted</strong>
                                    </template>
                                    <b-row>
                                        <b-col cols="md-4">
                                            <b-form-group label-for="search-lookback" label="From the Last:" size="sm">
                                                <b-input-group>
                                                    <b-select id="search-lookback" :options="lookbackOptions" v-model="lookback" size="sm">
                                                        <template #first>
                                                            <option :value="null"> - </option>
                                                        </template>
                                                    </b-select>
                                                    <template #append>
                                                        <b-button size="sm" @click="lookback = null">
                                                            <font-awesome-icon class="clear-button" icon="times"/>
                                                        </b-button>
                                                    </template>
                                                </b-input-group>
                                            </b-form-group>
                                        </b-col>
                                        <b-col cols="md-4">
                                            <b-form-group label-for="search-date-range" label="Date Range:">
                                                <picker-of-dates id="search-date-range" type="date" format="M/d/yyyy" v-model="fromToRange" range size="sm"/>
                                            </b-form-group>
                                        </b-col>
                                    </b-row>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <hr/>
                        <b-row>
                            <b-col cols="md-8">
                                <b-form-group>
                                    <template #label>
                                        <strong>User Receiving the Exception</strong>
                                    </template>
                                    <b-row>
                                        <b-col>
                                            <b-form-group label-for="search-last-name" label="Last Name:" size="sm">
                                                <b-input-group>
                                                    <b-input id="search-last-name" v-model="criteria.lastName" size="sm" placeholder="Last Name"/>
                                                    <template #append>
                                                        <b-button size="sm" @click="criteria.lastName = null">
                                                            <font-awesome-icon class="clear-button" icon="times"/>
                                                        </b-button>
                                                    </template>
                                                </b-input-group>
                                            </b-form-group>
                                        </b-col>
                                        <b-col>
                                            <b-form-group label-for="search-ubc-id" label="UBC ID:" size="sm">
                                                <b-input-group>
                                                    <b-input id="search-ubc-id" v-model="criteria.ubcId" size="sm" placeholder="U00001111 or U-0000-1111"/>
                                                    <template #append>
                                                        <b-button size="sm" @click="criteria.ubcId = null">
                                                            <font-awesome-icon class="clear-button" icon="times"/>
                                                        </b-button>
                                                    </template>
                                                </b-input-group>
                                            </b-form-group>
                                        </b-col>
                                    </b-row>
                                </b-form-group>
                            </b-col>
                            <b-col cols="md-4">
                                <b-form-group>
                                    <template #label>
                                        <strong>User Making the Exception</strong>
                                    </template>
                                    <b-form-group label-for="search-user" label="User:" size="sm">
                                        <b-input-group>
                                            <b-select id="search-user" :options="adminOptions" v-model="criteria.user" size="sm">
                                                <template #first>
                                                    <option :value="null"> - </option>
                                                </template>
                                            </b-select>
                                            <template #append>
                                                <b-button size="sm" @click="criteria.user = null">
                                                    <font-awesome-icon class="clear-button" icon="times"/>
                                                </b-button>
                                            </template>
                                        </b-input-group>
                                    </b-form-group>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-card-body>
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="text-right">
                <br/>
                <b-button-group size="sm">
                    <b-button class="mr-2" variant="dark" @click="getPdf">Generate PDF</b-button>
                    <b-button variant="info" @click="clearForm">Clear</b-button>
                </b-button-group>
            </b-col>
        </b-row>
    </div>
</template>


<script>
    import { Component, Vue } from 'vue-property-decorator';
    import _ from 'underscore';
    import PickerOfDates from '@/components/shared/PickerOfDates';
    import download from '@/util/downloader';
    import Breadcrumb from '@/views/menu/breadcrumb/breadcrumb';
    import {sprintf} from 'sprintf-js';
    import {errorModalOptions} from "../../../util/formatters";
    import {LOOKBACKS, VaccineRegistrationExceptionHistoryCriteria} from "../../../model/report";
    import {sub as subDate} from 'date-fns';

    @Component({
        components: {
            PickerOfDates
        }
    })
    export default class VaccineRegistrationExceptionHistory extends Vue {
        title = 'COVID-19 Registration Restriction Exception History';
        criteria = new VaccineRegistrationExceptionHistoryCriteria();

        lb = null;

        get lookback() {
            return this.lb;
        }

        set lookback(lb) {
            this.lb = lb;

            if (!_.isNull(lb)) {
                this.criteria.toDate = new Date();
                this.criteria.fromDate = subDate(this.criteria.toDate, lb.value);
            }
        }

        get fromToRange() {
            return [this.criteria.fromDate, this.criteria.toDate];
        }

        set fromToRange(range) {
            this.ftr = range;
            const [fd, td] = range;

            this.criteria.fromDate = fd;
            this.criteria.toDate = td;
        }

        async mounted() {
            this.$store.commit('addBreadcrumb', Breadcrumb.create(this.title, null, true));
            this.clearForm();
            try {
                await this.$store.dispatch('users/loadAdmins');
            } catch (error) {
                await this.$bvModal.msgBoxOk(error.message, errorModalOptions);
            }
        }

        get lookbackOptions() {
            return _.map(LOOKBACKS, lb => {
                return {
                    text: lb.label,
                    value: lb
                };
            });
        }

        get adminOptions() {
            return _.chain(this.$store.getters['users/admins'])
                .sort(u => u.lastName)
                .filter(u => u.statusId === 1)  //Exclude disabled users
                .map(u => {
                    return {
                        text: sprintf('%s, %s (%s)', u.lastName, u.firstName, u.username),
                        value: u.id
                    };
                })
                .value();
        }

        async getPdf() {
            const requestData = JSON.stringify(this.criteria.serialize());
            // console.log(requestData);
            try {
                await download('POST', '/api/v1/report/vaccine-registration-exception-history', requestData);
            } catch (error) {
                await this.$bvModal.msgBoxOk(error.message, errorModalOptions);
            }
        }

        clearForm() {
            this.lookback = null;
            this.criteria.clear();
        }
    }
</script>


<style scoped>
</style>